.blogTypeList ul{padding-left: 10px; margin-top: 10px;}
.blogTypeList ul li{ position:relative;}
.blogTypeList ul li::after{content: ""; width: 4px; height: 4px; position: absolute; top:50%; margin-top: -1px; left: 5px; background-color: #000;;}
.blogTypeList ul li .item{ padding-left: 15px; width: 190px;}
.blogTypeList ul li .item .type{font-size: 14px; line-height: 26px; color: #279eb0; cursor: pointer;}
.blogTypeList ul li .item .type:hover{ text-decoration:underline;}
.blogTypeList ul li .item .nums{ color: #323e32; cursor:inherit}
.blogTypeList ul li .current{ padding-top: 5px; background-color: #f4f9ef;color: #7c6833;}
.blogTypeList ul li .current .type{ font-weight: bolder; }
.blogTypeList ul li .current::after{ content: ""; width: 0px; height: 0px; position: absolute; margin-top: -5px; top: 50%; right:0px;
   border: 5px solid red;border-color:transparent transparent transparent #f4f9ef; }
