.searchPage{ width: 100%; position: relative; background-color: #e7fcff; padding: 50px 0px;}
.searchPage .searchPageCent{ width: 1000px; margin: 0px auto; background-color: #ffffff; padding: 40px 25px;}
.searchPage .searchPageCent h1{color: #49687F; font-weight: bolder; font-size:20px;}
.searchPage .searchPageCent h1 span{ color: #f00; margin: 0px 3px; letter-spacing: 2px;}
/* .searchPage .selectText{ color: #f00;} */
.searchPage .article{ width: 100%; position: relative; padding:15px; border-top: 1px solid #f1f1f1; margin-top: 20px;}
.searchPage .article_title_cont{ width: 100%; position:relative;}
.searchPage .article .titles{ font-size: 20px; line-height: 24px; font-weight: bolder; color: #2440b3;}
.searchPage .article .titles span,
.searchPage .article_body span{
  color: #f00;
}
.searchPage .article .timers{ font-size: 14px; line-height: 24px; position:absolute; top: 0px; right: 20px; color:#333;}
.searchPage .article_body{ font-size: 12px; color: #333; letter-spacing: 1px; line-height: 23px; margin-top: 30px; padding: 0px 15px 0px 0px;}
.searchPage .fangwen{ width: 100%;  text-align: right; font-size: 12px; padding-right: 20px; line-height: 24px; color:#637160 ;}