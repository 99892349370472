.signupBody { width: 100%; position: relative; background-repeat: repeat-x; background-position: 0px top; background-color: #dceaf5; padding-bottom: 80px;}
.signupBody .codebg{ width: 100%;position:relative; background-repeat: no-repeat; background-position: top center; padding-top: 80px;}
/* .signupBody .codebg .bg{ position: absolute; top: 0px; left: -50%; width: 1708px; transform:translateX(50%);} */
.signupBody .signupmargn{ width: 600px; margin: 0px auto; border-top: 2px solid #ff9000; background-color: #ffffff;}
.signupmargn h1{ margin-top: 20px; font-size:20px; padding-left: 20px; font-weight: bold; letter-spacing: 3px;}
.signupmargn .cent{ margin: 30px auto 0px; padding: 0px 0px 50px; width: 400px;}
.signupBody .item{ width: 400px; margin-top: 15px; position: relative;}
.signupBody .bitianItem::after{content: "*"; position: absolute; top: 0px; left: -15px; color: red; font-size: 20px;}
.signupBody .item .item_name{ font-size: 14px; margin: 0px 0px 5px; font-weight: bold;}
.signupBody .item .ant-select {width: 100%;}
.signupBody .item .w-e-text-container{border-top:1px solid #c9d8db !important;}
.signupBody .cent .submit{ width: 30%; display: block; margin: 35px auto 0px; }