.writePage{ width: 100%; position: relative; background-color: #e7fcff; padding: 50px 0px;}
.writePage .writePageCent{ width: 1000px; margin: 0px auto; background-color: #ffffff; padding: 30px 15px;}
.writePage .writePageCent h1{color: #49687F; font-weight: bolder; font-size:20px;}
.writePage .writePageCent h1 span{ font-size: 12px; margin-left: 20px; color: #f00;}
.writePage .writePageCent .titles{ font-size: 16px; line-height: 26px; margin: 20px 0px 10px;}
.writePage .writePageCent .titles input{ display: inline-block; width: 56%; height: 26px; }
.writePage .types{margin: 20px 0px 5px; font-size: 14px;}
.writePage .types span{ margin-right: 5px;}
.writePage .types p{ display: inline-block; margin-left: 35px; color: #900; text-decoration: underline; cursor: pointer;}

.writePage .writePageCent .tag{ vertical-align: middle;}
.writePage .writePageCent .tag .tagmain{ display: inline-block; width: 600px; border: 1px solid #d9d9d9;  line-height: 30px;vertical-align: middle;}
.writePage .writePageCent .tag .tagmain .taginputCont { width: 100%; height: 26px; line-height: 26px; position: relative;}
.writePage .writePageCent .tag .tagmain .taginputCont  .distag { height: 100%; position: absolute; top: 0px; left: 0px;}
.writePage .writePageCent .tag .tagmain .taginputCont .distag span{ margin: 0px 3px; background-color: #f4f4f4; height: 100%; line-height: 26px;
border: 1px solid #d6d6d6; padding: 0px 5px; font-size: 14px; color: #000000; border-radius: 2px;}
.writePage .writePageCent .tag .tagmain .taginputCont .distag span span{ margin: 0px; margin-left: 3px; border: none; padding: 0px; font-size: 13px; cursor: pointer; }



.writePage .writePageCent .tag .tagmain .taginputCont input{ width: 100%; height: 100%; border: none; background: none; outline: none; text-indent: 5px; 
  position: absolute;  top: 0px; right: 0px;}
.writePage .writePageCent .tag .tagCont{  width: 600px; background-color: #fffeeb; margin-left: 42px; border: 1px solid #d9d9d9;  border-top: none;
padding: 30px 15px 10px; position: relative; display: none;}
.writePage .writePageCent .tag .tagCont p{color: #807c7d; line-height: 21px;margin-top: -12px; font-size: 14px;}
.writePage .writePageCent .tag .tagCont .dingyitags,
.writePage .writePageCent .tag .tagCont .xitongtags {
  margin: 10px 0px;
  vertical-align: text-top;
}
.writePage .writePageCent .tag .tagCont .dingyitags span,
.writePage .writePageCent .tag .tagCont .xitongtags span {
  color: #807c7d;
  line-height: 21px;
  margin-top: -12px;
  font-size: 14px;
  vertical-align: text-top;
}
.writePage .writePageCent .tag .tagCont .dingyitags .dingyi,
.writePage .writePageCent .tag .tagCont .xitongtags .xitong {
  vertical-align: text-top;
  width: 430px;
  font-size: 12px;
  display: inline-block;;
}
.writePage .writePageCent .tag .tagCont .dingyitags .dingyi span,
.writePage .writePageCent .tag .tagCont .xitongtags .xitong span {
  margin: 0px 3px;
  background-color: #f4f4f4;
  height: 100%;
  line-height: 26px;
  border: 1px solid #d6d6d6;
  padding: 0px 5px;
  cursor: pointer;
  font-size: 14px;
  color: #000000;
  border-radius: 2px;
}

.writePage .writePageCent .setUp { margin: 20px 0px;}
.writePage .writePageCent .subBtns{ width: 30%; height: 50px; letter-spacing: 3px;  display: block; margin: 70px auto 40px; font-size: 20px; font-weight: bolder;}

/* .writePage .modeCont{ width: 90%; margin: 0px auto;}
.writePage .modeCont .titles input{ width: 50%;} */
.ant-modal-root .titles input{ width: 40%; margin-right: 5px;}
.ant-modal-root .titles .zhushi{ margin-left: 5px; font-size: 12px; color:#999 ;}
.ant-modal-root .fenglei_cont{ margin: 25px auto 15px;}
.ant-modal-root .fenglei_cont p { height: 35px;line-height: 35px; overflow: hidden; display: block;width: 100%; padding: 0px 15px; margin: 0px;
   color: #000000; position: relative;  border-bottom: 1px dashed #999;}
.ant-modal-root .fenglei_cont p:first-child{border-top: 1px dashed #999;}
.ant-modal-root .fenglei_cont .old { background-color: #f5f6f8;}
.ant-modal-root .fenglei_cont p span{ position: absolute; top: 0px; right: 10px; cursor: pointer; color:#2e3092 ;}
.ant-modal-root .fenglei_cont p span:hover{ text-decoration: underline;}

.writePage .noLoading {
  width: 100%;
  padding-top: 100px;
  font-size: 20px;
  text-align: center;
}
