* {
  margin: 0px;
  padding: 0px;
}

.homepage {
  position: relative;
}

.homepage .gg {
  width: 1000px;
  margin: 0px auto;
}

.homepage .gg img {
  width: 100%;
}

.homepage .homenav {
  width: 100%;
  position: relative;
  background-color: #df7e65;
}

.homepage .homenav .homenavCent {
  width: 1240px;
  margin: 0px auto
}

.homepage .homenav ul {
  margin-left: 240px;
  position: relative;
  font-size: 0px;
  list-style: none;
  list-style-type: none;
}

.homepage .homenav ul li {
  font-size: 18px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-weight: bold;
  padding: 0px 20px;
  margin: 0px 10px;
  cursor: pointer;
}

.homepage .homenav ul li:hover {
  background-color: #c96f60;
}

.homepage .homenav ul li a {
  color: #fff;
}

.homepage .homeBody {
  position: relative;
  width: 1240px;
  margin: 0px auto;
}

.homepage .homeBody .blog-left {
  width: 200px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.homepage .homeBody .blog-container {
  padding-top: 20px;
  margin-left: 240px;
}

.blog-container .blog-center {
  width: 640px;
  position: relative;
}

.blog-container .blog-right {
  width: 320px;
  position: absolute;
  top: 20px;
  right: 0px;
}

.blog-center .blog-news {
  margin-top: 32px;
}

.blog-center .blog-news .focus {
  padding-left: 60px;
  padding-bottom: 26px;
  border-bottom: 1px solid #ddd;
  position: relative;
  z-index: 1;
}

.blog-center .blog-news .focus .label {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 54px;
  background: #f2f2f2;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.blog-center .blog-news .focus .label p {
  height: 27px;
  font-size: 20px;
  color: #ad6f5b;
  letter-spacing: 0;
  text-align: center;
  overflow: hidden;
  margin: 0px;
}

.blog-center .blog-news .focus .label .label1 {
  font-weight: 300;
  line-height: 32px;
}

.blog-center .blog-news .focus .label .label2 {
  font-weight: 300;
  line-height: 22px;
}

.blog-center .blog-news .focus h2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
}

.blog-center .blog-news .focus h2 a {
  color: #ad6f5b;
}

.blog-center .blog-news .normal {
  margin-top: 8px;
}

.blog-center .blog-news .normal span {
  font-size: 16px;
  color: #333;
  letter-spacing: 0;
  line-height: 16px;
}

.blog-center .blog-news .normal span a {
  color: #333;
}

.blog-center .blog-news .normal .border-left {
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid #ddd;
}

.blog-center .blog-news .art-list li {
  position: relative;
  padding: 18px 0 14px 60px;
  border-bottom: 1px solid #ddd;
  line-height: 20px;
  font-size: 0;
}

.blog-center .blog-news .art-list li .num,
.blog-center .blog-news .art-list li a {

  font-weight: 100;
  font-size: 18px;
  color: #333;
  letter-spacing: 0;
}

.blog-center .blog-news .art-list li .num {
  position: absolute;
  line-height: 20px;
  left: 8px;
  top: 19px;

}

.blog-center .blog-news .art-list li a {
  font-weight: bolder;
}