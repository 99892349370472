.blogArticleContent .articlebody{    padding: 24px 20px 0;clear: both;    zoom: 1;}
.blogArticleContent .articalTitle{line-height: 20px;padding-bottom: 10px;}
.blogArticleContent .articalTitle .titleName{font-size: 18px;font-weight: 300;display: inline; color: #323e32;}
.blogArticleContent .articalTitle .timer{ font-size: 10px; margin-left: 5px; margin-right: 13px; }
.blogArticleContent .articalTitle a{ color: #279eb0; text-decoration: none; margin-left: 5px;}
.blogArticleContent .articalTitle a:hover{text-decoration: underline;}
.blogArticleContent .articalTag{ width: 100%;clear: both; word-break: break-all; line-height: 20px;}
.blogArticleContent .articalTag .cs_txtb{ color:#637160;}
.blogArticleContent .articalTag a { color: #279eb0;font-weight: bolder;}
.blogArticleContent .articalTag .cs_txtbTwo{ margin-left: 35px;}
.blogArticleContent .newfont_family{ width: 690px;clear: both; padding-top: 18px;font-size: 14px; line-height: 150%;
    padding-bottom: 30px;word-wrap: break-word; word-break: break-all; overflow: hidden;}
.blogArticleContent .articalInfo{clear: both;padding: 50px 0px 20px; border-bottom: 1px dashed #b3ccbc;}
.blogArticleContent .articalInfo span{color: #637160; margin-right: 5px;}
.blogArticleContent .articalInfo a{    color: #279eb0;margin-right: 5px}
.blogArticleContent .allComm{ margin-top: 30px;}
.blogArticleContent .allComm .allCommTit { background-color: #bfd8c5; border-radius: 5px; line-height: 20px;}
.blogArticleContent .allComm .allCommTit span{ margin: 0px 0px 0px 30px;}
.blogArticleContent .allComm .article_comment_list{ width: 100%; overflow: hidden; margin-top: 30px;}
.article_comment_list .commTitNanme a{font-weight: bold; color: #279eb0;}
.article_comment_list li{ padding-bottom: 20px;border-bottom: 1px dashed #b3ccbc;}
.article_comment_list .commTitCont{ line-height: 22px;clear: both;width: 100%;font-size: 14px; margin: 9px 0px 15px; word-break: break-all; }
.article_comment_list .myReFrom .timers{ margin-right: 12px;color: #637160;}
.article_comment_list .myReFrom .huifu{ float:right; color: #279eb0; margin-right: 15px; cursor: pointer; }
.article_comment_list .myReFrom .huifu a{  color: #279eb0; }
.blogArticleContent .allComm .commtit{ margin: 20px 0px;}
.blogArticleContent .allComm .wrCommTit{ width: 100%; position: relative; margin-bottom: 50px;}
.wrCommTit .wr_name{    line-height: 28px;}
.wrCommTit .wrCommTitText{ width: 100%; height: 80px; outline: none; resize: none;}
.wrCommTit .subCommTit{ width: 100px; display: block; margin: 20px auto 10px; }
.wrCommTit .tips{ color: #637160; width: 100%; text-align: center;}
.wrCommTit .onMask{ width: 100%; height: 100%; position: absolute; z-index: 100001; background-color: #000; opacity: 0.4; top: 0px; left: 0px;}
.wrCommTit .onMask .onlock{ position: absolute; top: 45%; left: 50%;-webkit-transform: translate(-50%,-50%); font-size: 50px; color: white;}
.wrCommTit .onMask p{ width: 100%; position: absolute; top: 63%; left: 0px;-webkit-transform: translateY(-50%); font-size: 12px; color:#fff; text-align: center;}

.blogArticleContent .tishi{ width: 100%; margin-bottom: 10px; text-align: center; font-size: 30px; color: #279eb0;}
.blogArticleContent table {
    border-top: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
}
.blogArticleContent table th,
.blogArticleContent table td {
        border-bottom: 1px solid #f1f1f1;
        border-right: 1px solid #f1f1f1;
        padding: 3px 5px;
        min-height: 30px;
}
.blogArticleContent table th{
       border-bottom: 2px solid #f1f1f1;
       text-align: center;
       background-color: #f1f1f1
}
