.loginCont{ width: 100%; max-height: 320px; padding-bottom: 20px;}
.loginCont p {font-size: 18px;color: #333;letter-spacing: 0; line-height: 18px;text-align: center; margin-bottom: 12px;}
.loginCont .inputs{ margin: 5px 0px;}
.loginCont .onLoginCont,.loginCont .end{ width: 100%; position: relative; margin-top: 10px;}
.loginCont .onLoginCont .checkbox{ position: absolute; right: 0px; top: 0px;}
.loginCont .onLoginCont .loginbtn{ width: 35%;}
.loginCont .end span{margin-right: 15px; cursor: pointer;}
.loginCont .end span a{color: #323e32;}
.loginCont .logined-user-info { padding-bottom: 17px; line-height: 60px;  border-bottom: 1px solid #ddd;}
.loginCont img { height: 60px;width: 60px;border-radius: 50%;float: left;display: inline;zoom: 1; cursor: pointer;}
.loginCont .logined-name,
.loginCont .logout-btn {zoom: 1;letter-spacing: 0;}
.loginCont .logined-name{display: block;width: 200px;margin-left: 14px; float: left;display: inline; font-size: 18px;  color: #333; cursor: pointer;}
.loginCont .logout-btn {float: right;display: inline;font-size: 14px;color: #999; cursor: pointer;}
.loginCont .logined-user-info:after {content: "";display: block;clear: both;}
.loginCont ul {overflow: hidden;  margin-top: 16px;  margin-bottom: 26px;}
.loginCont ul li{float: left;display: inline;zoom: 1;width: 115.5px;padding-left: 4px;letter-spacing: 0;line-height: 16px;}
.loginCont ul li:first-child{width: 75px;}
.loginCont ul li span {font-size: 16px;color: #bb8777;margin-left: 8px;}
.loginCont .logined-bottom-btn { margin-top: 24px; text-align: center;font-size: 0;}
.loginCont .logined-bottom-btn a {width: 137px;height: 36px;line-height: 36px;border: 1px solid #bc816e;border-radius: 18px;
  overflow: hidden;font-size: 16px;color: #ad6f5b;letter-spacing: 0;font-weight: 300; display: inline-block;}
.loginCont .logined-bottom-btn .to-set-interest{margin-right: 10px;}