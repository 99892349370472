.class-cropper-modal{position: fixed;background-color: rgba(0,0,0,0.3);top: 0;bottom: 0;left: 0;
  right: 0;display: flex;justify-content: center;align-items: center;}
.class-cropper-modal .modal-panel {width: 760px; background: white; padding: 20px;position: relative;}
.class-cropper-modal .cropper-container {width: 500px;height: 500px;}
.class-cropper-modal .preview-container {position: absolute;width: 184px;top: 338px;left: 550px;}
.class-cropper-modal .cropper {width: 100%; height: 100%;}
.class-cropper-modal .cropper-preview {width: 180px; height: 180px; overflow: hidden; border: 1px solid #383838;}
.class-cropper-modal .close { width: 50px; height: 50px; font-size: 36px; position: absolute;top: 0px;right: 0px; cursor: pointer;}
.class-cropper-modal .submit-button { 
  margin:30px auto 10px; display: block; width: 50%; letter-spacing: 3px; font-size: 20px; height: 40px; line-height: 30px;
}


   