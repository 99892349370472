.chengshanBlogB { background: none; background-repeat: no-repeat;background-position: center bottom;width: 100%;clear: both; padding-bottom: 100px;
   background-position: center top;background-color: #bfcdac; font-size: 12px; color: #323e32; letter-spacing: 1px; }
.chengshanBlogB .headarea {width: 950px; height: 266px;clear: both; margin: 0 auto;position: relative; z-index: 100;}
.headarea .blogtoparea{ position: absolute; left: 35px;top: 46.6%;;}
.blogtoparea .blogtitle { font-size: 24px;color: #474d28;font-weight: 300; margin: 0px; padding: 0px;}
.blogtoparea .bloglink {color: #4e5530; margin: 0px; padding-top: 5px;}
.headarea .blognav { position: absolute;width: 343px;height: 48px;left: 23px;top: 78%;}
.headarea .blognav .blognavInfo { position:absolute; top:0px; left:0px;}
.blognav .blognavInfo span { float: left;font-size: 14px; overflow: hidden; padding: 0 14px;  cursor: pointer; border-left: 2px dashed #000; }
.blognav .blognavInfo span:first-child{ border:none}
.blognav .blognavInfo span a{ color: #323e32;}
.blognav .blognavInfo .active{ font-weight: bold; font-size: 15px;}
.headarea .blogtopoption{ position: absolute; bottom: 15px; right: 5px;float: left; width: 69px;height: 23px; line-height: 23px;overflow: hidden;    text-align: center;
color: #595959;text-decoration: none; background-color: #ededed; border: 1px solid #acacac; border-radius: 2px; cursor: pointer;}
.chengshanBlogB .chegnshanblogbody{ width: 950px; clear: both; margin: 0px auto; z-index: 10; font-size: 0px;}
.chegnshanblogbody .column_1,.chegnshanblogbody .column_2{ width: 210px; display: inline-block; overflow: hidden;margin-left: 0; font-size: 12px;vertical-align: top}
.chegnshanblogbody .column_2{ margin-left: 10px;width: 730px;}
.chengshanBlogB .blogComp{ width: 100%; background-color: #ccd7bb; border: 1px solid #b5bf93;clear: both;margin: 0 0 10px;
  text-align: left;overflow: hidden; clear: both; padding: 15px 0px; }
.chengshanBlogB .blogComp .title{ padding-left: 10px; font-weight: 700;color: #7c6833;    clear: both;}
