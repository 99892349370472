.dakaCont{ width: 100%; margin: 10px 0px; padding: 20px 0px; border-top: 2px solid #bc816e;}
.dakaCont .titles {font-weight: 600;font-size: 18px; color: #ad6f5b; letter-spacing: 1.29px; margin-left: 4px;}
.dakaCont ul{ margin-top: 20px; list-style: none; list-style-type: none; width: 100%;}
.dakaCont ul li {position: relative;margin-bottom: 20px; margin-left: 68px;
 width: 248px; height: 80px; background-color: #f2f2f2;}
.dakaCont ul li img {width: 50px; height: 50px;position: absolute;top: 50%;left: -64px;margin-top: -25px; border-radius: 50%;overflow: hidden;}
.dakaCont ul li div{ position: relative;}
.dakaCont ul li div::after{content:"";  width: 0;height: 0; position: absolute; top: 48%; left: -10px;
    border-top: 8px solid transparent;
    border-right: 12px solid #f2f2f2;
    border-bottom:8px solid transparent;}
.dakaCont ul li div p {font-size: 14px; color: #444;letter-spacing: 0; line-height: 18px; margin: 0px 0px 5px;
  height: 46px;overflow: hidden; padding: 10px 23px 0px;font-weight: 600;} 
.dakaCont ul li div p a{ color: #444;}
.dakaCont ul li div span{margin-left: 23px;    font-size: 14px;color: #999;letter-spacing: 0;line-height: 16px;}