.pagenavcont{ width: 100%; background-color: #f1f1f1; position: relative; height: 30px; line-height: 30px; border-bottom: 1px solid #c3c3c3;}
.pagenav{ max-width: 1000px; margin: 0px auto; position: relative; }
.bokename{ font-size: 16px; font-weight: bold; line-height: 30px;}
.bokename a{color: #323e32;}
.pageloginCont{ position: absolute; top: 0px; right: 0px; font-size: 0px;}
.pageloginCont .loginOut,
.pageloginCont .navlogin_in {
  font-size: 0px;
  height: 30px;
}
.pageloginCont input{outline: none;}
.pageloginCont .navlitm{ display: inline; font-size: 14px; height: 30px; padding: 0px 15px; border-left: 1px solid #dadce0; cursor: pointer;}
.pageloginCont a .navlitm{    color: #323e32;}
.pageloginCont .navlitm a{color: #323e32;}
.pagenav .onsearchInput{
  position: absolute; top: 3px; left: 35%;
}