.updataTainFormationPage{ width: 100%; position: relative; background-color: #f6f6f6; padding-bottom: 50px; }
.updataTainFormationPage .myCont{ width: 100%; position: relative; background-color: #dcdcdc; padding: 40px 0px; border-bottom: 1px solid #cdcdcd;}
.updataTainFormationPage .myContCent{ width: 1000px; margin: 0px auto; position: relative;}
.updataTainFormationPage .myMain{ width: 96px; position: relative;}
.updataTainFormationPage .myMain img{ width: 100%;}
.updataTainFormationPage .myMain .username{ width: 200%; font-size: 20px; line-height: 20px; position: absolute; top: 0px; left: 125%; font-weight: bolder; color: #2e3092;}
.updataTainFormationPage .myMain .fangwen,
.updataTainFormationPage .myMain .ages{ width: 200%; font-size: 14px; color: #666666; position: absolute; top: 28px; left: 125%;}
.updataTainFormationPage .myMain .ages{ top: 52px;}
.updataTainFormationPage .myMain .fangwen span,
.updataTainFormationPage .myMain .ages span{ color: #2e3092;}
.updataTainFormationPage .mycont_nav{ width: 300%; position: absolute; bottom: 0px; left: 125%; list-style: none;color: #666666; 
  font-size: 14px; line-height: 16px; letter-spacing: 1px;}
.updataTainFormationPage .mycont_nav li a{ color: #666666;}
.updataTainFormationPage .mycont_nav li{ float: left; padding: 0px 10px; border-right: 1px dashed;}
.updataTainFormationPage .mycont_nav li:first-child{padding-left: 0px;}
.updataTainFormationPage .mycont_nav li:last-child{ border-right: none}
.updataTainFormationPage .write_article_cont{ width: 80px;background-color: #eeeeee;; border: 1px solid #cdcdcd; padding: 5px 0px; text-align: center;
position: absolute; top:8px; right: 80px; border-radius: 5px;}
.updataTainFormationPage .write_article_btn{ font-size: 50px; color: #666666;}
.updataTainFormationPage .write_article_cont p{ width: 100%; color: #666666; margin: 5px 0px 0px;}

.updataTainFormationPage .updataMian{ width: 1000px;position: relative; background-color: #fff; margin: 50px auto 0px; padding: 1px 0px 30px;  border: 1px solid #cdcdcd;}
.updataTainFormationPage .titles{ width: 100%; font-size: 14px; font-weight: bolder; line-height: 30px; text-indent: 20px; background-color: #ededed; color: #666690;}
.updataTainFormationPage .onupdata_tips{ width: 950px; margin: 25px auto 15px;}
.updataTainFormationPage .item{ width: 100%; margin-top: 15px; position: relative;}
.updataTainFormationPage .item .item_name{ font-size: 14px; font-weight: bold; width: 50px; display: inline-block; }
.updataTainFormationPage .item .jianjie_title{ width: 100%;}
.updataTainFormationPage .item .ant-select{ width: 100%;}
.updataTainFormationPage .item .upCont{ width: 860px; display: inline-block; }
.updataTainFormationPage .item .w-e-text-container{border-top:1px solid #c9d8db !important;}
.updataTainFormationPage .okBtns{ width: 30%; display: block; margin: 35px auto 0px; }
.updataTainFormationPage .base-upload-input,.updataTainFormationPage .updataHeadPic{ width: 100%; height: 100%;  position: absolute; top: 0px; left: 0px; cursor: pointer;}
.updataTainFormationPage .base-upload-input{ opacity: 0;}
.updataTainFormationPage .updataHeadPic .tishi{ width: 100%; background: rgba(0, 0, 0, 0.6); font-size: 14px; color: #fff; text-align: center; 
  line-height: 20px; position: absolute; left: 0px; bottom: 0px; display: none;}
.updataTainFormationPage .updataHeadPic:hover .tishi{ display: block;;}
.updataTainFormationPage .noLoading{ width: 100%; padding-top: 100px; font-size: 20px; text-align: center;}
