.visitorCont ul{ list-style: none; list-style-type: none; width: 90%; margin: 10px auto 0px; position: relative;}
.visitorCont ul li{ width: 100%; position: relative; font-size: 0px; vertical-align:middle;}
.visitorCont ul li img{ width:9px; display: inline-block; margin-right: 5px;}
.visitorCont ul li a,
.visitorCont ul li .data {
  font-size: 12px;
  text-decoration: none;
  color: #279eb0;
  line-height: 22px;
  vertical-align: middle;
}
.visitorCont ul li .data{ position: absolute; top: 0px; right: 0px; color: #555;}