.blogAllArticle .articles{padding-left: 10px; margin-top: 20px;}
.blogAllArticle .articles li{ position:relative; border-top: 1px dashed #b3ccbc;}
.blogAllArticle .articles li::after{content: ""; width: 4px; height: 4px; position: absolute; top:50%; margin-top: -1px; left: 5px; background-color: #000;;}
.blogAllArticle .articles li .item{ padding-left: 15px; color: #279eb0; line-height: 38px; padding-right: 15px; }
.blogAllArticle .articles li .item .articleTitle{font-size: 14px; line-height: 26px; cursor: pointer;color: #279eb0;}
.blogAllArticle .articles li .item .articleTitle:hover{ text-decoration:underline;}
.blogAllArticle .articles li .item .timer{ color: #637160; cursor:inherit; float: right;}
.blogAllArticle .articles li .item .change,.blogAllArticle .articles li .item .del{ float: right; margin-left: 5px; cursor: pointer;}
.blogAllArticle .articles li .item .change a{color: #279eb0;}
.blogAllArticle .articles li .item .change:hover,
.blogAllArticle .articles li .item .del:hover {
text-decoration:underline;
}
