.blogHomeListCont .blogHomeList{ width: 100%; font-size: 14px;padding: 0 20px 40px;}
.blogHomeList .blog_title_h{padding: 26px 0 10px;clear: both;}
.blogHomeList .blog_title_h .blog_title {font-size: 18px;display: inline; font-weight: 300;color: #279eb0; cursor: pointer;}
.blogHomeList .blog_title_h .CP_a_fuc{ color: #279eb0; display: inline-block; cursor: pointer;margin: 0px 5px;}
.blogHomeList .blog_title_h .time{ color: #637160; font-size: 12px; margin: 0px 5px;}
.blogHomeList .articalTag{width: 100%;clear: both;word-break: break-all;line-height: 20px;}
.blogHomeList .articalTag span{ color: #637160; font-size: 12px; margin-left: 20px;}
.blogHomeList .articalTag .first{ margin-left:0px;}
.blogHomeList .articalTag h3{ display: inline;font-size: 12px;    font-weight: 400;    margin-right: 5px;}
.blogHomeList .articalTag .type{ display: inline-block; color: #279eb0; cursor: pointer;}
.blogHomeList .content{    position: relative;font-size: 14px; clear: both;overflow: hidden;margin-bottom: 24px;padding-top: 20px;
    word-break: break-all; word-wrap: break-word;width: 689px;line-height: 150%;}
.blogHomeList .tagMore{clear: both;height: 15px; padding: 10px 0 50px; border-bottom: 1px dashed #279eb0;}
.blogHomeList .tagMore div{color: #279eb0; display: inline-block; margin-left: 10px; cursor: pointer;}
.blogHomeList .tagMore div a{color: #279eb0;}
.blogHomeList .tagMore div span{    color: #637160; cursor: default;}
.blogHomeList .ant-pagination{ margin: 30px  0px 0px; text-align: center;}